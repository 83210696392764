<template>
  <a-table
    :columns="columns"
    :data-source="list"
    bordered
    rowKey="id"
    :pagination="false"
    :cell-style="{ borderColor: 'red' }"
    :header-cell-style="{ borderColor: 'red' }"
  >
    <template v-slot:generateTime="record">
      <span :title="record | formatDate('YYYY-MM-DD HH:mm:ss')">{{
        record | formatDate("YYYY-MM-DD HH:mm:ss")
      }}</span>
    </template>
    <tempalte v-slot:firstUseTime="record">
      <span :title="record | formatDate('YYYY-MM-DD HH:mm:ss')">{{
        record | formatDate("YYYY-MM-DD HH:mm:ss")
      }}</span>
    </tempalte>
    <tempalte v-slot:expireTime="record">
      <span :title="record | formatDate('YYYY-MM-DD HH')">{{
        record | formatDate("YYYY-MM-DD HH")
      }}</span>
    </tempalte>
    <template slot="operation" slot-scope="text, record">
      <a-popconfirm
        v-if="list.length"
        title="确定删除?"
        cancelText="取消"
        okText="确定"
        @confirm="() => handleDelete(record)"
      >
        <a href="javascript:;" class="delete">删除</a>
      </a-popconfirm>
    </template>
  </a-table>
</template>
<script>
import { Table, Popconfirm } from "ant-design-vue";
import { deleteAuthCode } from "../../../request/package";
const columns = [
  {
    title: "设备名称",
    dataIndex: "name",
    width: "21%",
    align: "center",
  },
  {
    title: "生效时间",
    dataIndex: "generateTime",
    width: "22%",
    scopedSlots: { customRender: "generateTime" },
    ellipsis: true,
    align: "center",
  },
  {
    title: "初次使用时间",
    dataIndex: "firstUseTime",
    width: "22%",
    scopedSlots: { customRender: "firstUseTime" },
    ellipsis: true,
    align: "center",
  },
  {
    title: "到期时间",
    dataIndex: "expireTime",
    width: "22%",
    scopedSlots: { customRender: "expireTime" },
    ellipsis: true,
    align: "center",
  },
  {
    title: "操作",
    // width: "13%",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    align: "center",
  },
];

export default {
  components: {
    [Table.name]: Table,
    [Popconfirm.name]: Popconfirm,
  },
  data() {
    return {
      columns,
      editingKey: "",
    };
  },
  props: {
    list: Array,
  },
  methods: {
    handleDelete(record) {
      deleteAuthCode(record.id).then(() => {
        this.$myToast({
          type: "success",
          message: "删除成功！",
        });
        this.$emit("update-table");
      });
    },
    save(key) {
      console.log(key);
    },
    cancel(key) {
      console.log(key);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.delete:hover {
  color: $toast-text-error;
}

//设置表头样式
::v-deep .ant-table-thead > tr > th {
  background: #d6e0ff;
  border: 1px solid #eef6ff;
}

::v-deep .ant-table-thead {
  //border: 1px solid #eef6ff;
}

// 消除table表头左右的圆角
::v-deep .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0;
}

::v-deep .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0;
}

// 设置表格边框
::v-deep .ant-table-row > td {
  border: 1px solid #d6e0ff;
  border-bottom: none;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
}

//设置表格行的背景
::v-deep .ant-table-row {
  background: #ffffff;
}
</style>
