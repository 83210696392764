<template>
  <div class="content-container">
    <card-title :title="$t('layout.user.info.title')" />
    <div class="info-wrap">
      <account-info :authStatus="authStatus" :remark="remark" />
      <!--<device-info :auth-status="authStatus" />-->
    </div>
    <package-info :auth-status="authStatus" />
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import AccountInfo from "@/views/user/info/AccountInfo";
// import DeviceInfo from "@/views/user/info/DeviceInfo";
import PackageInfo from "@/views/user/info/PackageInfo";
import { mapMutations } from "vuex";
import { checkRealName } from "@/request/user";
import { myAccount } from "../../../request/user";
import { setUserInfoInLocal } from "../../../utils/utils";

export default {
  components: {
    PackageInfo,
    // DeviceInfo,
    CardTitle,
    AccountInfo,
    // [Button.name]: Button,
    // [List.name]: List,
    // [List.Item.name]: List.Item,
  },
  data() {
    return {
      // isAuth: false,
      // isBuy: false,
      authStatus: undefined,
      packageInfo: {},
      remark: "",
    };
  },
  created() {
    this.getAuthStatus();
    this.getAccount();
  },
  computed: {},
  methods: {
    ...mapMutations({
      setUserInfo: "user/setUserInfo",
    }),
    getAuthStatus() {
      checkRealName().then((res = {}) => {
        const { realNameAuthState } = res;
        this.authStatus = realNameAuthState;
      });
    },
    getAccount() {
      myAccount().then((res = {}) => {
        const { name, phone, id, realNameAuditRemark } = res;
        const userInfo = {
          id,
          name,
          phone,
        };
        this.remark = realNameAuditRemark;
        this.setUserInfo(userInfo);
        setUserInfoInLocal(userInfo);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrap {
  padding-left: 80px;
}
</style>
