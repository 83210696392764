<template>
  <common-modal ref="modal" :destroyOnClose="true">
    <div class="modal-content">
      <div class="text">
        <div>
          授权码已生效：<span class="code">{{ code }}</span
          >（仅显示一次）
        </div>
        <div>
          请手动复制以上授权密码或点击下方“关闭，复制授权码”按钮，将授权密码复制到粘贴板。同时请尽快打开e路播客户端，将授权密码粘贴到输入框，完成登录。
        </div>
      </div>
      <a-button
        type="primary"
        @click="copyAndClose"
        :style="btnStyle"
        class="close-btn"
        >关闭并复制授权码</a-button
      >
    </div>
  </common-modal>
</template>

<script>
import CommonModal from "../../../components/CommonModal";
import { Button } from "ant-design-vue";
import { convertPxToVw } from "../../../utils/format";
import { autoCopy } from "../../../utils/utils";

export default {
  components: {
    CommonModal,
    [Button.name]: Button,
  },
  data() {
    return {
      btnStyle: {
        width: convertPxToVw(200),
        height: convertPxToVw(44),
      },
      code: "",
    };
  },
  methods: {
    setModalVisible(bool, params = {}) {
      const { code } = params;
      this.code = code;
      this.$refs.modal.setModalVisible(bool);
    },
    copyAndClose() {
      autoCopy(this.code);
      this.$refs.modal.setModalVisible(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

::v-deep .ant-modal-content .ant-modal-footer {
  padding-bottom: 0;
}

.modal-content {
  width: 100%;
  @include flex(column, null, center);
}

.text {
  font-size: 16px;
  color: $primary-text-color;
  line-height: 27px;

  .code {
    color: $primary-color;
    user-select: text;
  }
}

.close-btn {
  letter-spacing: 2px;
  margin-top: 24px;
}
</style>
