<template>
  <!--<div class="auth-code-modal">-->
  <common-modal
    ref="modal"
    title="获取授权码"
    :maskClosable="true"
    @after-close="resetForm"
  >
    <a-form
      v-bind="formItemLayout"
      :hideRequiredMark="true"
      :form="form"
      autocomplete="off"
    >
      <a-form-item label="设备名称">
        <a-input
          size="large"
          placeholder="请输入设备名称"
          allow-clear
          v-decorator="['name', formConfig.name]"
        ></a-input>
      </a-form-item>
      <a-form-item label="到期时间">
        <a-date-picker
          style="width: 100%"
          size="large"
          value-format="YYYY-MM-DD"
          :showToday="false"
          :disabled-date="disabledDate"
          v-decorator="['expireTime', formConfig.expireTime]"
          placeholder="请选择到期时间"
        >
        </a-date-picker>
      </a-form-item>
    </a-form>
    <div slot="footer">
      <a-button-group>
        <a-button
          type="primary"
          size="large"
          :style="btnStyle"
          :loading="loading"
          @click="confirm"
          >{{ $t("layout.authModal.confirm") }}</a-button
        >
        <a-button
          size="large"
          :style="btnStyle"
          @click="() => setModalVisible(false)"
          >取消</a-button
        >
      </a-button-group>
    </div>
  </common-modal>
  <!--</div>-->
</template>

<script>
import { Modal, Button, Form, Input, DatePicker } from "ant-design-vue";
import { convertPxToVw } from "@/utils/format";
import { createAuthCode } from "../../../request/package";
import CommonModal from "../../../components/CommonModal";
// import dayjs from "dayjs";
import moment from "moment";

export default {
  components: {
    [Modal.name]: Modal,
    [Button.name]: Button,
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Input.name]: Input,
    [Button.Group.name]: Button.Group,
    [DatePicker.name]: DatePicker,
    CommonModal,
  },
  data() {
    return {
      show: false,
      loading: false,
      btnStyle: {
        width: convertPxToVw(140),
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 },
        },
      },

      form: this.$form.createForm(this),
      endDate: null,
      defaultDate: null,
    };
  },
  props: {},
  computed: {
    // 默认选择时间往后一年或者套餐到期日期
    formConfig() {
      const defaultDate = this.initDefaultDate();
      return {
        name: {
          rules: [
            {
              required: true,
              message: "请输入设备名称",
            },
          ],
        },
        expireTime: {
          rules: [{ required: true, message: "请选择到期时间" }],
          initialValue: defaultDate,
        },
      };
    },
  },
  methods: {
    initDefaultDate() {
      let defaultDate = null;
      if (this.endDate) {
        const oneYearLater = moment().add(1, "year");
        const momentEndDate = moment(this.endDate);
        defaultDate = !this.disabledDate(oneYearLater)
          ? oneYearLater
          : this.disabledDate(momentEndDate)
          ? null
          : momentEndDate;
      }
      return defaultDate;
    },
    // 不可选日期
    disabledDate(current) {
      // Can not select days before today and today
      return (
        current &&
        (current < moment().endOf("day") ||
          (this.endDate && current > moment(this.endDate).endOf("day")))
      );
    },
    setModalVisible(bool, params = {}) {
      this.show = bool;
      const { endDate } = params;
      this.endDate = endDate;
      this.$refs.modal.setModalVisible(bool);
    },
    confirm() {
      this.form.validateFieldsAndScroll((err, fieldsValue) => {
        if (!err) {
          const params = {
            name: fieldsValue.name,
            expireTime: moment(fieldsValue.expireTime).format("YYYY-MM-DD"),
          };
          this.loading = true;
          createAuthCode(params)
            .then((res) => {
              this.loading = false;
              this.setModalVisible(false);
              this.$emit("create-success", res);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.show = false;
      console.log("after");
      this.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.ant-form-item {
  //display: flex;
  margin-bottom: 20px;

  &:last-child {
    //margin-bottom: 40px;
  }

  ::v-deep .ant-form-item-label {
    //width: 320px;
    color: $primary-text-color;
  }
  input {
    //width: 320px;
  }

  .ant-select {
    //width: 320px;
  }
}
</style>
