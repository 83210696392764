<template>
  <div ref="authModal" class="auth-modal">
    <a-modal
      :title="$t('layout.authModal.title')"
      centered
      :visible="showModal"
      :closable="true"
      maskClosable
      :getContainer="() => $refs.authModal"
      :width="modalWidth"
      :dialogStyle="dialogStyle"
      @ok="() => setModalVisible(false)"
      @cancel="() => setModalVisible(false)"
    >
      <div class="auth-method">
        <div
          class="personal"
          :class="{ active: authType === 0 }"
          @click="authType = 0"
        >
          <div class="title-wrap">
            <i></i>
            <span class="title">{{
              $t("layout.authModal.personal.title")
            }}</span>
          </div>
          <div class="description">
            <p
              v-for="item in $t('layout.authModal.personal.description')"
              :key="item"
            >
              {{ item }}
            </p>
          </div>
        </div>
        <div
          class="enterprise"
          :class="{ active: authType === 1 }"
          @click="authType = 1"
        >
          <div class="title-wrap">
            <i></i>
            <span class="title">{{
              $t("layout.authModal.enterprise.title")
            }}</span>
          </div>
          <div class="description">
            <p
              v-for="item in $t('layout.authModal.enterprise.description')"
              :key="item"
            >
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <div slot="footer">
        <a-button-group>
          <a-button
            type="primary"
            size="large"
            :style="btnStyle"
            @click="toAuth"
            >{{ $t("layout.authModal.confirm") }}</a-button
          >
          <a-button
            type="primary"
            size="large"
            ghost
            :style="btnStyle"
            @click="() => setModalVisible(false)"
            >{{ $t("layout.authModal.cancel") }}</a-button
          >
        </a-button-group>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Modal, Button } from "ant-design-vue";
import { convertPxToVw } from "@/utils/format";

export default {
  components: {
    [Modal.name]: Modal,
    [Button.name]: Button,
    [Button.Group.name]: Button.Group,
  },
  data() {
    return {
      showModal: false,
      modalWidth: convertPxToVw(800),
      dialogStyle: {
        paddingBottom: 0,
        minWidth: "500px",
      },
      btnStyle: {
        width: convertPxToVw(140),
      },
      authType: 0,
    };
  },
  props: {},
  methods: {
    setModalVisible(bool) {
      this.showModal = bool;
    },
    toAuth() {
      this.$router.push({
        path:
          this.authType === 0 ? "/user/personal-auth" : "/user/enterprise-auth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.auth-modal {
  ::v-deep .ant-modal-content {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      background: #f3f6ff;
      height: 40px;
      padding: 0 0 0 24px;
      @include flex(row, null, center);
      border-bottom: none;

      .ant-modal-title {
        font-size: $font-size-description;
        color: #333333;
      }
    }

    .ant-modal-body {
      border-radius: 10px;
    }

    .ant-modal-footer {
      border-top: none;
    }
  }

  .auth-method {
    height: 242px;
    @include flex();
    color: $primary-text-color;
    background: $white;
    .personal {
      width: 50%;
      padding: 30px 32px 0;
      border: 1px solid #eef4ff;
      border-right: none;
    }

    .enterprise {
      width: 50%;
      border: 1px solid #eef4ff;
    }

    .personal,
    .enterprise {
      padding: 30px 32px 0;

      &.active {
        background: #eef4ff;
      }
    }

    .title-wrap {
      margin-bottom: 20px;
    }

    .title {
      font-weight: bold;
    }

    .description {
      line-height: 27px;
      font-size: 16px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
