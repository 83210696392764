<template>
  <a-modal
    :title="title"
    centered
    :visible="show"
    :closable="true"
    :maskClosable="maskClosable"
    :width="modalWidth"
    :dialogStyle="dialogStyle"
    :afterClose="afterClose"
    :destroyOnClose="destroyOnClose"
    @cancel="() => setModalVisible(false)"
  >
    <i slot="closeIcon" class="iconfont icon-icon_tianjia-xian"></i>
    <template v-slot:default>
      <slot name="default"></slot>
    </template>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </a-modal>
</template>

<script>
import { convertPxToVw } from "../utils/format";

export default {
  data() {
    return {
      show: false,
      modalWidth: convertPxToVw(538),
      dialogStyle: {
        paddingBottom: 0,
        width: convertPxToVw(538),
        // minWidth: "500px",
      },
    };
  },
  props: {
    title: String,
    maskClosable: Boolean,
    destroyOnClose: Boolean,
  },
  methods: {
    setModalVisible(bool) {
      this.show = bool;
    },
    afterClose() {
      this.$emit("after-close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

::v-deep .ant-modal-content {
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .ant-modal-close-x {
    height: 40px;
    line-height: 40px;
  }

  .icon-icon_tianjia-xian {
    color: #999;
    font-size: 20px;
    display: inline-block;
    transform: rotate(45deg);
  }

  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    background: #f3f6ff;
    height: 40px;
    padding: 0 0 0 24px;
    @include flex(row, null, center);
    border-bottom: none;

    .ant-modal-title {
      font-size: $font-size-description;
      color: #333333;
    }
  }

  .ant-modal-body {
    border-radius: 10px;
  }

  .ant-modal-footer {
    border-top: none;
    padding-top: 0;
    padding-bottom: 26px;
  }
}
</style>
