<template>
  <div class="package-info-container">
    <!--未购买套餐-->
    <div v-if="!packages.length" class="no-purchase">
      <span class="title">{{ $t("layout.user.info.noBuyTitle") }}</span>
      <a-button
        type="primary"
        size="large"
        shape="round"
        :style="buyBtnStyle"
        @click="buyNow(ConnectionTypeEnum.Direct)"
        >{{ $t("layout.user.info.buyNow") }}</a-button
      >
    </div>
    <template v-else>
      <div class="my-package-title">我的套餐</div>
      <div
        class="package-wrap"
        v-for="item in packages"
        :key="item.id"
        :class="{ direct: item.connectionType === ConnectionTypeEnum.Direct }"
      >
        <div class="purchased-header">
          <div class="title-container">
            <span class="package-name">{{ item.name }}</span>
            <span>({{ item.duration }}{{ item.timeUnit }})</span>
          </div>
          <div>
            <span class="deal-time">{{
              item.endDate | formatDate("YYYY-MM-DD")
            }}</span>
            <!--正式套餐且实名才显示优惠续费按钮-->
            <a-button
              type="primary"
              size="large"
              shape="round"
              :style="renewBtnStyle"
              @click="buyNow(item.connectionType)"
              v-if="isAuth && item.productType === ProductTypeEnum.Formal"
              >{{ $t("layout.user.info.renew") }}</a-button
            >
          </div>
        </div>
        <div
          class="device-info"
          v-if="item.connectionType === ConnectionTypeEnum.Vpn"
        >
          <div>
            设备数量：<span class="device-num">{{ item.contents.length }}</span
            >台在使用
          </div>
          <a-button
            class="code-btn"
            type="primary"
            shape="round"
            ghost
            :style="codeBtnStyle"
            @click="clickCreateCode(item.endDate)"
            >获取授权码</a-button
          >
        </div>
        <package-table
          v-if="item.contents.length"
          :list="item.contents"
          @update-table="getPacket"
        />
      </div>
    </template>
    <auth-code-modal
      ref="authCodeModal"
      @create-success="handleCreateSuccess"
    />
    <auth-code-success-modal ref="authCodeSuccessModal" />
  </div>
</template>

<script>
import { convertPxToVw } from "@/utils/format";
import { Button } from "ant-design-vue";
import { AuthStatus } from "@/config/user";
import PackageTable from "./PackageTable";
import { userPackage } from "../../../request/user";
import AuthCodeModal from "./AuthCodeModal";
import AuthCodeSuccessModal from "./AuthCodeSuccessModal";
import { ConnectionTypeEnum } from "../../../config/system";
import { ProductTypeEnum } from "../../../config/layout";

export default {
  components: {
    [Button.name]: Button,
    PackageTable,
    AuthCodeModal,
    AuthCodeSuccessModal,
  },
  data() {
    return {
      buyBtnStyle: {
        minWidth: convertPxToVw(200),
      },
      renewBtnStyle: {
        minWidth: convertPxToVw(160),
      },
      codeBtnStyle: {
        minWidth: convertPxToVw(120),
        height: convertPxToVw(36),
      },
      packages: [],
      ConnectionTypeEnum,
      ProductTypeEnum,
    };
  },
  props: {
    packageName: String,
    buyTime: Number,
    authStatus: Number,
  },
  computed: {
    isAuth() {
      return this.authStatus === AuthStatus.AUTHED;
    },
  },
  created() {
    this.getPacket();
  },
  methods: {
    getPacket() {
      userPackage().then((res = []) => {
        this.packages = res;
      });
    },
    clickCreateCode(endDate) {
      this.$refs.authCodeModal.setModalVisible(true, {
        endDate,
      });
    },
    handleCreateSuccess(code) {
      this.getPacket();
      this.$refs.authCodeSuccessModal.setModalVisible(true, {
        code,
      });
    },
    buyNow(connectionType) {
      if (this.isAuth) {
        this.$router.push({
          path: "/user/purchase",
          query: {
            connectionType,
          },
        });
      } else {
        this.$myToast({
          type: "error",
          message: this.$t("message.buyWhenNoAuth"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.package-info-container {
  .no-purchase {
    padding-left: 80px;
    height: 130px;
    @include flex(row, space-between, center);
  }

  .title,
  .title-container,
  .my-package-title {
    font-size: $font-size-menu-title;
    color: $primary-text-color;
    font-weight: bold;
  }

  .my-package-title {
    height: 68px;
    line-height: 68px;
    padding-left: 80px;
  }

  .package-wrap {
    padding-left: 80px;
    background: #d0e0fe;
    padding-right: 40px;
    padding-bottom: 24px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.direct {
      padding-bottom: 0;
    }
  }

  .purchased-header {
    height: 80px;
    @include flex(row, space-between, center);

    .title-container {
      font-weight: 500;
    }

    .package-name {
      margin-right: 12px;
    }

    .deal-time {
      font-size: $font-size-description;
      color: $primary-text-color;
      margin-right: 24px;
    }
  }

  .device-info {
    @include flex(row, null, center);
    font-size: 18px;
    color: $primary-text-color;
    margin-bottom: 16px;
    .device-num {
      color: $primary-color;
    }

    .code-btn {
      font-size: 18px;
      color: $primary-color;
      border-radius: 4px;
      margin-left: 40px;
    }
  }
}
</style>
