<template>
  <div>
    <div class="account-info">
      <div class="info">
        <div class="title-container">
          <span class="title">{{ $t("layout.user.info.accountTitle") }}</span>
          <span>
            (<span :class="auth.className">{{ auth.text }}</span
            >)
          </span>
        </div>
        <div class="phone">
          {{ $t("layout.user.info.phone") }}：{{ userInfo.phone }}
        </div>
      </div>
      <a-button
        v-if="
          authStatus === AuthStatus.REFUSE || authStatus === AuthStatus.UNAUTH
        "
        type="primary"
        shape="round"
        :style="buttonStyle"
        size="large"
        @click="toAuth"
        >{{
          authStatus === AuthStatus.UNAUTH ? "实名认证" : "重新提交"
        }}</a-button
      >
    </div>
    <auth-modal ref="authModal" />
  </div>
</template>
<script>
import { Button } from "ant-design-vue";
import { convertPxToVw } from "@/utils/format";
import { mapGetters } from "vuex";
import { AuthStatus } from "@/config/user";
import AuthModal from "@/views/user/info/AuthModal";

export default {
  components: {
    [Button.name]: Button,
    AuthModal,
  },
  data() {
    return {
      buttonStyle: {
        width: convertPxToVw(200),
      },
      AuthStatus,
    };
  },
  props: {
    authStatus: Number,
    remark: String,
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
    }),
    auth() {
      return this.authStatus === AuthStatus.AUTHED
        ? { text: this.$t("layout.user.info.authed"), className: "authed" }
        : this.authStatus === AuthStatus.AUTHING
        ? { text: this.$t("layout.user.info.authing"), className: "auth-ing" }
        : this.authStatus === AuthStatus.UNAUTH
        ? { text: this.$t("layout.user.info.noAuth"), className: "no-auth" }
        : this.authStatus === AuthStatus.REFUSE
        ? { text: `未通过实名审核，原因：${this.remark}`, className: "refuse" }
        : {};
    },
    authed() {
      return this.authStatus === AuthStatus.AUTHED;
    },
  },
  methods: {
    toAuth() {
      this.$router.push("/user/enterprise-auth");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.account-info {
  @include flex(row, space-between, center);
  height: 130px;
  border-bottom: 1px solid #c4d6fc;

  .info {
    @include flex(column, center);

    .title-container {
      font-size: $font-size-menu-title;
      margin-bottom: 18px;
      font-weight: bold;

      .title {
        color: $primary-text-color;
      }

      .authed {
        color: $success-color;
      }

      .no-auth,
      .refuse {
        color: $not-auth-color;
      }

      .auth-ing {
        color: $auth-ing-color;
      }
    }

    .phone {
      font-size: $font-size-description;
      color: $primary-text-color;
    }
  }
}
</style>
